import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CDefinition,
  CSeparateCol,
  CShopPrivate02,
  CVariableSlider,
  LContact04,
  LStores,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import '../../../assets/_sass/page/private.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'レストラン個室のご案内',
            sub: <>PRIVATE ROOM</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/private/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/private/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="u_pt100 u_mbLarge">
        <LWrap>
          <p className="c_sectLead u_fs16">
            ご家族やご友人とのお祝いや大切な記念日はもちろん、ビジネスでの接待や会食のご利用など、
            <br className="u_pc" />
            集うシーンに合わせてお選びいただける個室をご用意しております。
            <br />
            プライベートな空間で贅沢なひとときをお過ごしください。
          </p>
          <CVariableSlider
            exClass="u_mt90"
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private02.png',
                  alt: '',
                },
              },
            ]}
          />
          <CSeparateCol
            exClass="u_mt30"
            left={
              <>
                <h3 className="title u_fwm">鉄板焼 すみだ</h3>
                <p className="content u_mt20">
                  和牛や魚介類を目の前で調理しサービスする鉄板焼「すみだ」では、8名様までご利用いただける個室をご用意しております。
                  <br />
                  ホテル最上階の景色とともに鉄板を前にしたカウンターで、お食事や会話をじっくりと堪能できるのが魅力です。
                </p>
                <ul className="c_noteList u_mt40">
                  <li>料金にはサービス料・消費税が含まれます。</li>
                </ul>
              </>
            }
            right={
              <>
                <CShopPrivate02 data={shopGet('sumida')} />
              </>
            }
          />
          <CVariableSlider
            exClass="u_mt90"
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private03.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private04.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private11.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private12.png',
                  alt: '',
                },
              },
            ]}
          />
          <CSeparateCol
            exClass="u_mt30"
            left={
              <>
                <h3 className="title u_fwm">日本料理 源氏香</h3>
                <p className="content u_mt20">
                  日本庭園を臨む和個室には掘りごたつ式のテーブルが配され、洋個室は、落ち着いた雰囲気の中、ゆったりとお寛ぎいただけます。
                  <br />
                  大切な日のお顔合せや、記念日、ご家族でのお集りなど、さまざまなシーンにご利用いただけます。
                </p>
                <ul className="c_noteList u_mt40">
                  <li>料金にはサービス料・消費税が含まれます。</li>
                </ul>
              </>
            }
            right={
              <>
                <CShopPrivate02 data={shopGet('genjikoh')} />
              </>
            }
          />
          <CVariableSlider
            exClass="u_mt90"
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private05.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private14.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private06.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private13.png',
                  alt: '',
                },
              },
            ]}
          />
          <CSeparateCol
            exClass="u_mt30"
            left={
              <>
                <h3 className="title u_fwm">中国料理 桂花苑</h3>
                <p className="content u_mt20">
                  お料理を彩り豊かに並べて楽しむ中国料理で和やかな宴を演出します。2名様から40名様までの個室をご用意しております。
                  <br />
                  ご家族の慶事や、ビジネスのお祝いのお席など、かけがえのない大切なひとときを、広東料理を囲みながらお過ごしください。
                </p>
                <ul className="c_noteList u_mt40">
                  <li>料金にはサービス料・消費税が含まれます。</li>
                </ul>
              </>
            }
            right={
              <>
                <CShopPrivate02 data={shopGet('kei_ka_en')} />
              </>
            }
          />
          <CVariableSlider
            exClass="u_mt90"
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private07.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private08.png',
                  alt: '',
                },
              },
            ]}
          />
          <CSeparateCol
            exClass="u_mt30"
            left={
              <>
                <h3 className="title u_fwm">すし処 日本橋 矢の根寿司</h3>
                <p className="content u_mt20">
                  「矢の根寿司」は日本橋に店を構え半世紀余り、懐かしい味・いつもの味・新しい味を大切にしております。
                  <br />
                  大切な方との記念日や接待など幅広くご活用いただける個室をご用意しております。
                  <br />
                  熟練職人の技が光る江戸前寿司をご堪能ください。
                </p>
                <ul className="c_noteList u_mt40">
                  <li>ラストオーダーは営業時間の30分前です。</li>
                  <li>
                    連休中日の日曜日及び祝日の営業時間は土曜日と同じになります。
                  </li>
                </ul>
              </>
            }
            right={
              <>
                <CShopPrivate02 data={shopGet('yanone')} />
              </>
            }
          />
          <CVariableSlider
            exClass="u_mt90"
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private09.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_private10.png',
                  alt: '',
                },
              },
            ]}
          />
          <CSeparateCol
            exClass="u_mt30 p_rsCol"
            left={
              <>
                <h3 className="title u_fwm">メインバー ロイヤルスコッツ</h3>
                <p className="content u_mt20">
                  英国の古き佳き時代を思わせる空間で、グラスを傾けながら、ゆっくりと流れる時間に身をまかせお過ごしください。
                  <br />
                  お仲間同士の集まりなどシチュエーションに合わせてご利用いただける個室をご用意しております。
                </p>
                <ul className="c_noteList u_mt40">
                  <li>料金にはサービス料・消費税が含まれます。</li>
                </ul>
              </>
            }
            right={
              <>
                <CShopPrivate02 data={shopGet('royal_scots')} />
              </>
            }
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact04 />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
